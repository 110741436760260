"use client";

import { Fragment } from "react";
import Link from "next/link";
import Image from "next/image";
import { isEmpty } from "lodash";
import parse from "html-react-parser";
import { A11y, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Modal } from "@/components/modal";
import { Hero } from "@/components/hero";
import { Button } from "@/components/button";

import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/pagination";
import "swiper/css/autoplay";

export const TestimonialModal = ({ testimonial, trigger }) => {
  const { uri, title, logo, content, author, authorTitle } = testimonial;

  return (
    <Modal trigger={trigger}>
      <div>
        <Hero title={title} size="sm" />
        <div className="relative p-5">
          {logo?.sourceUrl && (
            <div className="inline-block w-32 h-32 mx-auto -mt-16 mb-5 p-1 rounded-full border-2 border-black/5 bg-white">
              <div className="relative w-full pb-[100%] rounded-full overflow-hidden">
                <Image
                  className="object-cover"
                  src={logo.sourceUrl}
                  alt={logo.altText}
                  fill
                />
              </div>
            </div>
          )}
          <div className="text-left mt-8">
            <div className="prose my-5">{parse(content)}</div>
            <div>
              {author && <h2 className="text-lg mb-0">{author}</h2>}
              {authorTitle && (
                <p className="text-sm italic max-w-sm mt-0">{authorTitle}</p>
              )}
            </div>
            <Button className="mt-10" asChild>
              <Link href={uri}>View Story</Link>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const TestimonialSlider = ({ testimonials }) => {
  if (isEmpty(testimonials)) return null;

  return (
    <div className="px-5 py-24 bg-gray-50">
      <Swiper
        modules={[A11y, Pagination, Autoplay]}
        slidesPerView={1}
        a11y={{ enabled: true }}
        pagination={{ clickable: true }}
        autoplay={{ delay: 6000 }}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        {testimonials.map((testimonial, index) => {
          const { logo, excerpt, author, authorTitle } = testimonial;

          return (
            <SwiperSlide key={index}>
              <TestimonialModal
                testimonial={testimonial}
                trigger={({ setOpen }) => (
                  <div
                    className="text-center max-w-2xl mx-auto pb-10"
                    role="button"
                    tabIndex={0}
                    onClick={() => setOpen(true)}
                  >
                    {logo?.sourceUrl && (
                      <div className="inline-block w-32 h-32 mx-auto mb-5 p-1 rounded-full border-2 border-black/5">
                        <div className="relative w-full pb-[100%] rounded-full overflow-hidden">
                          <Image
                            className="object-cover"
                            src={logo.sourceUrl}
                            alt={logo.altText}
                            fill
                          />
                        </div>
                      </div>
                    )}
                    <div className="text-xl leading-relaxed mb-8 line-clamp-3">
                      “{excerpt}”
                    </div>
                    <div className="text-left inline-flex flex-wrap items-center">
                      {author && <h2 className="text-lg">{author}</h2>}
                      {authorTitle && (
                        <Fragment>
                          <span className="mx-4">|</span>
                          <p className="text-sm italic max-w-sm">
                            {authorTitle}
                          </p>
                        </Fragment>
                      )}
                    </div>
                  </div>
                )}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
