"use client";

import InnerHTML from "dangerously-set-html-content";

export const Form = ({ form, ...props }) => (
  <InnerHTML className="form" html={form} {...props} />
);

export const FormSection = ({ form }) => {
  return (
    <div className="px-5 py-24">
      <div className="max-w-2xl mx-auto">
        <Form form={form} />
      </div>
    </div>
  );
};
