"use client";

import dynamic from "next/dynamic";
import { useInView } from "react-intersection-observer";

const CountUp = dynamic(() => import("react-countup"), { ssr: false });

export const StatsCounterItem = ({ title, start, end }) => {
  const { ref, inView } = useInView();

  return (
    <div ref={ref} className="text-white text-center px-5 py-8">
      {inView && (
        <CountUp
          start={start}
          end={end}
          separator=","
          duration={10}
          // useEasing={true}
          // easingFn={easingFn}
          style={{
            fontSize: "2rem",
            fontWeight: 600,
            display: "block",
            marginBottom: "5px",
          }}
        />
      )}
      <h3 className="text-base">{title}</h3>
    </div>
  );
};
